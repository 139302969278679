<template>
    <div class="task taskDetail">
        <addTask :visible="showDrawer" @Close="showDrawer=false" :data="addData" @submit="GetTaskList(userId)" :userId="userId"></addTask>
        <div class="clearfix">
            <span class="title csP" @click="$router.push('/home/task')"><a-icon type="left" style="color:#6a87e6;margin-right:10px"/>选择员工</span>
            <!-- <a-button class="btn fR">发送&nbsp;1/3</a-button> -->
            <a-button class="btn border fR mr20px" @click="()=>{showDrawer=true;addData = null}">添加任务</a-button>
        </div>
         <Loading v-if="loading"></Loading>
        <div class="taskDetailCont" v-if="result">
            <div class="left">
                <ul class="taskList">
                     <li class="">
                        <div class="topInfo">
                            <div class="headImg"><img :src="result.headImg" alt="" v-if="result.headImg"></div>
                            <div>
                                <p class="name">{{result.nickname}}</p>
                                <button class="sBtn">复制账号密码</button>
                            </div>
                        </div>
                        <div>
                            <p><span class="point"></span>总接收任务:<span class="mainColor">40</span></p>
                            <p><span class="point"></span>已完成:<span class="mainColor">40</span></p>
                            <p><span class="point"></span>待完成:<span class="mainColor">40</span></p>
                        </div>
                      </li>
                </ul>
            </div>
            <div>
                <div v-if="result.list.length">

                    <div class="examList" v-for="(item, index) in result.list" :key="index">
                        <div>
                            <!-- <label class="z_checkbox"><input type="checkbox"><span></span></label> -->
                            <span class="num">{{index | num}}</span>
                            <span class="name">{{item.title}}</span>
                        </div>
                        <div style="width:80px;text-align:right">
                            <a-icon type="form" class="csP" style="color:#4c6ee2;margin-right:15px" @click="()=>{showDrawer=true;addData = item}"/>
                            <a-icon type="delete" class="csP" style="color:#e03b3b;" @click="DeleTask(item)"/>
                        </div>
                    </div>
                </div>
                <div v-else style="text-align:center;font-size:18px;margin-top:50px">暂无数据</div>
            </div>
        </div>
    </div>
</template>
<script>
import addTask from '@/components/addTask'
import { getTaskList, delTask } from '@/libs/api'
import Loading from '@/components/loading'

export default {
    name:'taskList',
    components:{ addTask, Loading },
    data(){
        return{
            loading:true,
            result:null,
            current:1,
            showDrawer:false,
            addData:null,
            userId:''
        }
    },
    filters:{
        num(val){
            if (val<9){
                return `0${val+1}`
            }else{
                return val
            }
        }
    },
    mounted(){
        let id = this.$route.query.id
        this.userId = id
        this.GetTaskList(id)
    },
    methods:{
        onChange(){

        },
        copyAccount(item, e){
            e.stopPropagation()
            this.copyData = `账号:${item.username}`
            this.$copyText(this.copyData).then(res=> {
                this.$message.success('复制成功')
            },error=>{
                this.$message.success('复制成功')
                console.log(error)
            })
        },
        GetTaskList(id){
            this.loading = true
            getTaskList({user_id :id}).then(res=>{
                if(res){
                    this.result = res
                }
                this.loading = false
            },error=>{
                this.loading = false
                console.log(error)
            })
        },
        DeleTask({id}){
            delTask({id}).then(res=>{
                this.GetTaskList(this.userId)
            },error=>{
                console.log(error)
            })
        }
    }
}
</script>
<style lang="less" scoped>
.taskDetail{
    .btn{
        width: auto;
        padding: 0 30px;
    }
    .taskDetailCont{
        width: 100%;
        padding-top: 30px;
        display: flex;
        // >*{
        //     display: table-cell;
        //     vertical-align: top
        // }
        .left{
            width: 380px
        }
        >div:last-child{
            width: calc(100% - 380px)

        }

    }
    &.task .taskList{
        li{
            margin: 0;
        }
    }
}
</style>