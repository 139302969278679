<template>
  <div class="taskDrawer">
    <a-drawer
      :title="title"
      :width="720"
      @close="onClose"
      destroyOnClose
      :visible="visible"
      :wrapStyle="{height: 'calc(100% - 108px)',overflow: 'auto',paddingBottom: '108px'}"
      :wrapClassName="'addTaskDrawer'"
    >
      <a-form layout="vertical" hideRequiredMark>
        <div class="inputBox">
          <span class="point"></span>
          <div class="inputWrap">
            <a-form-item>
              <a-cascader :fieldNames="{ label: 'cname', value: 'id', children: 'son' }" :defaultValue="defaultValue" :options="options" @change="onChange" placeholder="请选择试卷分类" class="noBorder"/>
            </a-form-item>
          </div>
        </div>
        <div class="inputBox">
          <span class="point"></span>
          <div class="inputWrap">
            <a-form-item>
              <a-input placeholder="输入大标题" class="noBorder" v-model="add.title"/>
            </a-form-item>
          </div>
        </div>
         <div class="inputBox">
          <span class="point"></span>
          <div class="inputWrap">
            <a-form-item>
              <a-upload
              name="file"
              @change="changeUpload"
              :customRequest="customRequest"
              :fileList='fileList'
              class="noBorder"
            >
              <a-button>上传附件</a-button>
            </a-upload>
            </a-form-item>
<!-- 
             <a-upload
              name="file"
              :beforeUpload="beforeUpload"
              @change="changeUpload"
              :remove="handleRemove"
              class="noBorder"
              :fileList="fileList"
            > -->
          </div>
        </div>
        <div class="inputBox">
          <span class="point"></span>
          <div class="inputWrap">
            <a-form-item>
               <a-input placeholder="编辑者注意事项" class="noBorder" v-model="add.creater_care"/>
            </a-form-item>
          </div>
        </div>
        <div class="inputBox">
          <span class="point"></span>
          <div class="inputWrap">
            <a-form-item>
               <a-input placeholder="试卷注意事项" class="noBorder" v-model="add.paper_care"/>
            </a-form-item>
          </div>
        </div>
        <div class="inputBox grade">
          <div class="gradeSet"><span class="point"></span><span class="title">分值设置</span></div>
          <div class="pl35x">
            <div class="gradeList">
              <span class="title">总分</span>
              <div class="inputWrap">
                <a-input
                  :value="add.total_score"
                  :maxLength="25"
                  style="width: 120px"
                  @change="add.total_score=numDetector($event)"
                  class="hasText"
                />
                <div class="rightTest">分</div>
              </div>
            </div>
            <div class="gradeList">
              <span class="title">总题量</span>
              <div class="inputWrap">
                <a-input
                  :value="add.total_subject"
                  :maxLength="25"
                  style="width: 120px"
                  @change="add.total_subject=numDetector($event)"
                  class="hasText"
                />
                <div class="rightTest">题</div>
              </div>
            </div>
          </div>
          <div style="margin:20px 0">一、选择题</div>
          <div class="pl35x mb20x">
            <div class="gradeList">
              <span class="title">单选题</span>
              <div class="inputWrap">
                <a-input
                  :value="add.single_choice_num"
                  :maxLength="25"
                  style="width: 120px"
                  @change="add.single_choice_num=numDetector($event)"
                  class="hasText"
                />
                <div class="rightTest">题</div>
              </div>
            </div>
            <div class="gradeList">
              <div class="inputWrap">
                <a-input
                  :value="add.single_choice_each_score"
                  :maxLength="25"
                  style="width: 120px"
                  @change="add.single_choice_each_score=numDetector($event)"
                  class="hasText tText"
                />
                <div class="rightTest">分/题</div>
              </div>
            </div>
            <div class="gradeList mR0">
              <div class="inputWrap">
                <a-input
                  :value="computedNum( add.single_choice_num, add.single_choice_each_score )"
                  :maxLength="25"
                  style="width: 120px"
                  disabled
                  class="hasText"
                />
                <div class="rightTest">分</div>
              </div>
            </div>
          </div>
          <div class="pl35x mb20x">
            <div class="gradeList">
              <span class="title">多选题</span>
              <div class="inputWrap">
                <a-input
                  :value="add.multiple_choice_num"
                  :maxLength="25"
                  style="width: 120px"
                  @change="add.multiple_choice_num=numDetector($event)"
                  class="hasText"
                />
                <div class="rightTest">题</div>
              </div>
            </div>
            <div class="gradeList">
              <div class="inputWrap">
                <a-input
                  :value="add.multiple_choice_each_score"
                  :maxLength="25"
                  style="width: 120px"
                  @change="add.multiple_choice_each_score=numDetector($event)"
                  class="hasText tText"
                />
                <div class="rightTest">分/题</div>
              </div>
            </div>
            <div class="gradeList mR0">
              <div class="inputWrap">
                <a-input
                  :value="computedNum( add.multiple_choice_num, add.multiple_choice_each_score )"
                  :maxLength="25"
                  style="width: 120px"
                  disabled
                  class="hasText"
                />
                <div class="rightTest">分</div>
              </div>
            </div>
          </div>
          <div class="mb20x">
            <div class="gradeList">
              <span class="title" style="width:100px">二、判断题</span>
              <div class="inputWrap">
                <a-input
                  :value="add.jud_question"
                  :maxLength="25"
                  style="width: 120px"
                  @change="add.jud_question=numDetector($event)"
                  class="hasText"
                />
                <div class="rightTest">题</div>
              </div>
            </div>
            <div class="gradeList">
              <div class="inputWrap">
                <a-input
                  :value="add.jud_question_each_score"
                  :maxLength="25"
                  style="width: 120px"
                  @change="add.jud_question_each_score=numDetector($event)"
                  class="hasText tText"
                />
                <div class="rightTest">分/题</div>
              </div>
            </div>
            <div class="gradeList mR0">
              <div class="inputWrap">
                <a-input
                  :value="computedNum( add.jud_question, add.jud_question_each_score )"
                  :maxLength="25"
                  style="width: 120px"
                  disabled
                  class="hasText"
                />
                <div class="rightTest">分</div>
              </div>
            </div>
          </div>
          <div class="mb20x">
            <div class="gradeList">
              <span class="title" style="width:100px">三、填空题</span>
              <div class="inputWrap">
                <a-input
                  :value="add.fill_num"
                  :maxLength="25"
                  style="width: 120px"
                  @change="add.fill_num=numDetector($event)"
                  class="hasText"
                />
                <div class="rightTest">题</div>
              </div>
            </div>
            <div class="gradeList">
              <div class="inputWrap">
                <a-input
                  :value="add.fill_each_score"
                  :maxLength="25"
                  style="width: 120px"
                  @change="add.fill_each_score=numDetector($event)"
                  class="hasText tText"
                />
                <div class="rightTest">分/题</div>
              </div>
            </div>
            <div class="gradeList mR0">
              <div class="inputWrap">
                <a-input
                  :value="computedNum( add.fill_num, add.fill_each_score )"
                  :maxLength="25"
                  style="width: 120px"
                  disabled
                  class="hasText"
                />
                <div class="rightTest">分</div>
              </div>
            </div>
          </div>
          <div class="mb20x">
            <div class="gradeList">
              <span class="title" style="width:100px">四、简答题</span>
              <div class="inputWrap">
                <a-input
                  :value="add.answer_num"
                  :maxLength="25"
                  style="width: 120px"
                  @change="add.answer_num=numDetector($event)"
                  class="hasText"
                />
                <div class="rightTest">题</div>
              </div>
            </div>
            <div class="gradeList">
              <div class="inputWrap">
                <a-input
                  :value="add.answer_each_score"
                  :maxLength="25"
                  style="width: 120px"
                  @change="add.answer_each_score=numDetector($event)"
                  class="hasText tText"
                />
                <div class="rightTest">分/题</div>
              </div>
            </div>
            <div class="gradeList mR0">
              <div class="inputWrap">
                <a-input
                  :value="computedNum( add.answer_num, add.answer_each_score )"
                  :maxLength="25"
                  style="width: 120px"
                  disabled
                  class="hasText"
                />
                <div class="rightTest">分</div>
              </div>
            </div>
          </div>
           <div class="mb20x">
            <div class="gradeList">
              <span class="title" style="width:100px">五、论述题</span>
              <div class="inputWrap">
                <a-input
                  :value="add.material_num"
                  :maxLength="25"
                  style="width: 120px"
                  @change="add.material_num=numDetector($event)"
                  class="hasText"
                />
                <div class="rightTest">题</div>
              </div>
            </div>
            <div class="gradeList">
              <div class="inputWrap">
                <a-input
                  :value="add.material_each_score"
                  :maxLength="25"
                  style="width: 120px"
                  @change="add.material_each_score=numDetector($event)"
                  class="hasText tText"
                />
                <div class="rightTest">分/题</div>
              </div>
            </div>
            <div class="gradeList mR0">
              <div class="inputWrap">
                <a-input
                  :value="computedNum( add.material_num, add.material_each_score )"
                  :maxLength="25"
                  style="width: 120px"
                  disabled
                  class="hasText"
                />
                <div class="rightTest">分</div>
              </div>
            </div>
          </div>
        </div>
      </a-form>
      <div
        :style="{
          position: 'absolute',
          left: 0,
          bottom: 0,
          width: '100%',
          borderTop: '1px solid #e9e9e9',
          padding: '10px 16px',
          background: '#edf5f8',
          textAlign: 'right',
        }"
      >
        <a-button @click="submit" type="primary">确定</a-button>
      </div>
    </a-drawer>
  </div>
</template>
<script>
import { getCate, addTask, uploadImg, editTask } from '@/libs/api'
export default {
    name:'addTask',
    props:['visible','data','userId'],
    data() {
      return {
        form: this.$form.createForm(this),
        title: '添加任务',
        add:{
          cate_id: '',
          title: '',
          file_id:'',
          file_name:'',
          creater_care: '',
          paper_care: '',
          total_score: null,
          total_subject: null,
          single_choice_num: null,
          single_choice_each_score :null,
          multiple_choice_num: null,
          multiple_choice_each_score: null,
          jud_question: null,
          jud_question_each_score: null,
          fill_num: null,
          fill_each_score: null,
          answer_num: null,
          answer_each_score: null,
          material_num: null,
          material_each_score: null
        },
        options: [],
        fileList:[],
        fileUrl:'',
        defaultValue:[]
      };
    },
    computed:{
    },
    watch:{
      visible(val){
        if(val){
          this.reset()
          this.GetCate().then(res=>{
            if(this.data){
              this.title='编辑任务'
              this.add = JSON.parse(JSON.stringify(this.data))
              this.getCateArr(this.data.cate_id)
              this.fileUrl = this.data.file_url
              this.fileList=[{
                uid: this.data.file_id,
                name: this.data.file_name,
                status: 'done',
                url: this.data.file_url,
              }]
            }else{
              this.title="添加任务"
            }
          })
        }
      }
    },
    // updated(){
    //   if(this.data.length){
    //     this.title='编辑任务'
    //   }else{
    //     this.title="添加任务"
    //   }
    // },
    methods: {
      reset(){
        this.defaultValue = []
        this.fileList = []
        this.add={
          cate_id: '',
          title: '',
          file_id:'',
          file_name:'',
          creater_care: '',
          paper_care: '',
          total_score: null,
          total_subject: null,
          single_choice_num: null,
          single_choice_each_score :null,
          multiple_choice_num: null,
          multiple_choice_each_score: null,
          jud_question: null,
          jud_question_each_score: null,
          fill_num: null,
          fill_each_score: null,
          answer_num: null,
          answer_each_score: null,
          material_num: null,
          material_each_score: null
        }
      },
      getCateArr(id){
        let optionsCopy = JSON.parse(JSON.stringify(this.options))
        let optionsArr = []
        optionsCopy.forEach(el => {
          optionsArr.push(el)
          if(el.son.length){
            el.son.forEach(item=>{
              optionsArr.push(item)
              if(item.son.length){
                item.son.forEach(data=>{
                  optionsArr.push(data)
                })
              }
            })
          }
        });
        let ar1 = optionsArr.filter(el=>el.id==id)
        this.defaultValue.push(ar1[0].id)
        let ar2 = optionsArr.filter(el=>el.id == ar1[0].parent_id)
        this.defaultValue.unshift(ar2[0].id)
        let ar3 = optionsArr.filter(el=>el.id==ar2[0].parent_id)
        this.defaultValue.unshift(ar3[0].id)
      },
      computedNum( val1, val2 ){
        if( val1 && val2 ){
          return val1*val2
        }else{
          return null
        }
      },
      changeUpload(info){
        let fileList = [...info.fileList];
        fileList = fileList.slice(-1);
        fileList = fileList.map(file => {
          if (file.response) {
            file.url = file.response.url;
          }
          return file;
        });
        this.fileList = fileList;
      },
      customRequest(data){
        let formData = new FormData()
        formData.append('file',data.file)
        uploadImg(formData).then(res=>{
          if(res){
            this.fileList[0].status='done'
            this.add.file_name = this.fileList[0].name
            this.add.file_id = this.fileList[0].uid
            this.fileUrl=res.fileUrl
          }
        },error=>{
          console.log(error)
        })
      },
      GetCate(){
        return new Promise((resolve,reject)=>{
          getCate().then(res=>{
            if(res.length){
              this.options=res
              resolve()
            }
          },error=>{
            console.log(error)
            reject()
          })
        })
      },
      onClose() {
          this.$emit('Close')
      },
      onChange(id){
        this.add.cate_id=id[2]
      },
      numDetector(e){
        const { value } = e.target;
        const reg = /^-?(0|[1-9][0-9]*)(\.[0-9]*)?$/;
        if ((!isNaN(value) && reg.test(value)) || value === '' || value === '-') {
          return value
        }
      },
      submit(){
        if(!this.add.cate_id){
          this.$message.error('请选择学科分类')
          return false
        }
        if(!this.add.title){
          this.$message.error('标题不能为空')
          return false
        }
        if(!this.add.creater_care){
          this.$message.error('编辑者注意事项不能为空')
          return false
        }
        if(!this.add.paper_care){
          this.$message.error('试卷注意事项不能为空')
          return false
        }
        if(!this.add.total_score){
          this.$message.error('总分不能为空')
          return false
        }
        if(!this.add.total_subject){
          this.$message.error('总题量不能为空')
          return false
        }
        if(!this.fileUrl){
          this.$message.error('请上传附件')
          return false
        }
        let formData = new FormData()
        formData.append('user_id',this.userId)
        formData.append('file_id',this.add.file_id)
        formData.append('file_name',this.add.file_name)
        formData.append('file_url',this.fileUrl)
        formData.append('cate_id',this.add.cate_id)
        formData.append('title',this.add.title)
        formData.append('creater_care',this.add.creater_care)
        formData.append('paper_care',this.add.paper_care)
        formData.append('total_score',this.add.total_score)
        formData.append('total_subject',this.add.total_subject)
        formData.append('single_choice_num',this.add.single_choice_num)
        formData.append('single_choice_each_score',this.add.single_choice_each_score)
        formData.append('multiple_choice_num',this.add.multiple_choice_num)
        formData.append('multiple_choice_each_score',this.add.multiple_choice_each_score)
        formData.append('jud_question',this.add.jud_question)
        formData.append('jud_question_each_score',this.add.jud_question_each_score)
        formData.append('fill_num',this.add.fill_num)
        formData.append('fill_each_score',this.add.fill_each_score)
        formData.append('answer_num',this.add.answer_num)
        formData.append('answer_each_score',this.add.answer_each_score)
         formData.append('material_num',this.add.material_num)
        formData.append('material_each_score',this.add.material_each_score)
        if(!this.data){ //新增
          addTask(formData).then(res=>{
            if(res != 'underfind'){
              this.onClose()
              this.$emit('submit')
            }
          },error=>{
            console.log(error)
          })
        }else{  //修改
          formData.append('id',this.data.id)
          editTask(formData).then(res=>{
            if(res != 'underfind'){
              this.onClose()
              this.$emit('submit')
            }
          },error=>{
            console.log(error)
          })
        }
      }
    },
};
</script>
<style lang="less" scoped>
.pl35x{
  padding-left: 35px
}
.inputBox{
  background: #fff;
  min-height: 82px;
  border-radius: 4px;
  padding:25px 50px;
  display: flex;
  width: 100%;
  margin-bottom: 25px;
  align-items: center;
  &.grade{
    display: block;
    font-size: 18px;
    color: #999999;
  }
  >*{
    vertical-align: middle
  }
  .point{
    width: 9px;
    height: 9px;
    border-radius: 100%;
    background: #5180e0
  }
  .inputWrap{
    padding-left: 50px;
    flex: 1;
    /deep/ .ant-cascader-picker-label{
      font-size: 18px;
      height: 30px;
    }
  }
  .noBorder /deep/ .ant-input,  .noBorder /deep/ .ant-input:focus,.noBorder,.noBorder:focus,.noBorder /deep/ .ant-btn{
    font-size: 16px;
    border: none;
    box-shadow:none
  }
}
.ant-form-item{
  margin-bottom: 0;
  padding-bottom: 0
}
.gradeSet{
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  .point{
    margin-right: 50px
  }
  .title{
    color: #999;
    font-size: 18px
  }
}
.gradeList{
  display: inline-block;
  vertical-align: middle;
  margin-right: 30px;
  &.mR0{margin-right: 0}
  >*{
    display: inline-block;
    vertical-align: middle;
  }
  >.title{
    width:65px;
    font-size: 18px;
    color: #999999;
      display: inline-block;
    vertical-align: middle;
  }
  .inputWrap{
    padding-left: 0;
    position: relative;
    .rightTest{
      position: absolute;
      right: 0;
      width: 50px;
      font-size: 17px;
      color: #999999;
      top: 0;
      line-height: 40px;
      padding-right: 8px;
      text-align: right
    }
  }
}
.hasText.ant-input{
  font-size: 18px;
  padding-right: 20px;
  height: 40px;
  line-height: 40px
}
.tText.hasText{
  padding-right: 50px
}
.ant-form {
  padding-bottom:25px
}
</style>